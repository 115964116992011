// import '../scss/main.scss';
import particlesJS from 'particles.js';
import Swiper from 'swiper';
import { Scrollbar} from 'swiper/modules';
document.addEventListener("DOMContentLoaded", () => {

  const swiper = new Swiper('.swiper', {
    slidesPerView: 'auto',
    spaceBetween: 32,
    // centeredSlides: true,
    modules: [Scrollbar],
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
      dragSize: 230
    },
  });


  document.querySelectorAll(".js-blur-disable").forEach( item => {
        item.addEventListener("click", () => {
            item.classList.toggle('blur-box');
            item.querySelector(".js-eye").classList.toggle("hidden");
        });
    });

    const open = document.querySelector('.js-menu-open');
    const close = document.querySelector('.js-menu-close');
    const menu = document.querySelector('.js-menu');
    const header = document.querySelector('.js-header');

    open.addEventListener('click', ()=> {
      menu.classList.add('flex');
      menu.classList.remove('hidden');
      open.classList.add('hidden');
      close.classList.remove('hidden');
    });

    close.addEventListener('click', ()=> {
      menu.classList.remove('flex');
      menu.classList.add('hidden');
      close.classList.add('hidden');
      open.classList.remove('hidden');
    });


  let prevScrollTop = 0;

  const checkScrollDirection = (event) => {
    if (checkScrollDirectionIsUp(event)) {
      header.classList.remove('-top-[5.25rem]');
      header.classList.add('top-0');
    } else {

      header.classList.add('-top-[5.25rem]');
      header.classList.remove('top-0');
    }
  }

  const checkScrollDirectionIsUp = () => {
    const currentScrollTop = document.documentElement.scrollTop
    const tempPrev = prevScrollTop;
    prevScrollTop = currentScrollTop
    return Math.abs(tempPrev) > Math.abs(document.documentElement.scrollTop);
  }

  document.addEventListener('scroll', checkScrollDirection);



  const clickOutside = (element, callback) => {
    const handleClickOutside = (event) => {
      const path = event.path || (event.composedPath && event.composedPath());
      if (!path.includes(element)) {
        callback();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return {
      remove() {
        document.removeEventListener('click', handleClickOutside);
      },
    };
  };

  document.querySelectorAll('.js-dropdown-trigger').forEach(dropdown => {
    dropdown.addEventListener('click', ()=> {
      document.querySelector(`.js-dropdown[data-id=${dropdown.dataset.id}]`)
        .classList.toggle('hidden');
    });

    clickOutside(dropdown, ()=> {
      document.querySelector(`.js-dropdown[data-id=${dropdown.dataset.id}]`)
        .classList.add('hidden');
    })
  });

  // Show modal
  document.querySelectorAll('.js-modal-trigger').forEach(trigger => {
    trigger.addEventListener('click', ()=> {
      const modal = document.querySelector(`.js-modal[data-id=${trigger.dataset.id}]`)
      const close = document.querySelector(`.js-modal-close[data-id=${trigger.dataset.id}]`)
      modal.classList.add('top-0')
      modal.classList.remove('top-full')
      close.classList.remove('hidden');
    })
  });

  // Hide modal
  document.querySelectorAll('.js-modal-close').forEach(trigger => {
    trigger.addEventListener('click', ()=> {
      const modal = document.querySelector(`.js-modal[data-id=${trigger.dataset.id}]`)
      modal.classList.remove('top-0')
      modal.classList.add('top-full')
      trigger.classList.add('hidden');
    })
  });

  document.querySelectorAll('.js-modal').forEach((modal => {
    modal.addEventListener('click',(event) => {
      if(event.target.classList.contains('js-modal')) {
        const close = document.querySelector(`.js-modal-close[data-id=${modal.dataset.id}]`)
        modal.classList.remove('top-0')
        modal.classList.add('top-full')
        close.classList.add('hidden');
      }
    });
  }))

  if (document.querySelector('#particles-js-left')) {
    window.particlesJS('particles-js-left', {
      "particles": {
        "number": {
          "value": 20,
          "density": {
            "enable": true,
            "value_area": 1657
          }
        },
        "color": {
          "value": "#8312c2"
        },
        "shape": {
          "type": "image",
          "stroke": {
            "width": 0,
            "color": "#000000"
          },
          "polygon": {
            "nb_sides": 5
          },
          "image": {
            "src": "https://effectivelab.cz/molecule.4a367b75.png",
            "width": 30,
            "height": 30
          }
        },
        "opacity": {
          "value": 1,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 1,
            "opacity_min": 0.1,
            "sync": false
          }
        },
        "size": {
          "value": 60,
          "random": false,
          "anim": {
            "enable": true,
            "speed": 0.5,
            "size_min": 40,
            "sync": true
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 600,
          "color": "#8917c8",
          "opacity": 0.8,
          "width": 10
        },
        "move": {
          "enable": true,
          "speed": 1,
          "direction": "none",
          "random": true,
          "straight": false,
          "out_mode": "bounce",
          "bounce": false,
          "attract": {
            "enable": true,
            "rotateX": 600,
            "rotateY": 1200
          }
        }
      },
      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": false,
            "mode": "repulse"
          },
          "onclick": {
            "enable": false,
            "mode": "push"
          },
          "resize": true
        },
        "modes": {
          "grab": {
            "distance": 400,
            "line_linked": {
              "opacity": 1
            }
          },
          "bubble": {
            "distance": 400,
            "size": 40,
            "duration": 2,
            "opacity": 8,
            "speed": 3
          },
          "repulse": {
            "distance": 200,
            "duration": 0.4
          },
          "push": {
            "particles_nb": 4
          },
          "remove": {
            "particles_nb": 2
          }
        }
      },
      "retina_detect": true
    })
  }

  if (document.querySelector('#particles-js-right')) {
    window.particlesJS('particles-js-right', {
      "particles": {
        "number": {
          "value": 16,
          "density": {
            "enable": false,
            "value_area": 20657
          }
        },
        "color": {
          "value": "#8312c2"
        },
        "shape": {
          "type": "image",
          "stroke": {
            "width": 0,
            "color": "#000000"
          },
          "polygon": {
            "nb_sides": 5
          },
          "image": {
            "src": "https://effectivelab.cz/molecule.4a367b75.png",
            "width": 30,
            "height": 30
          }
        },
        "opacity": {
          "value": 1,
          "random": false,
          "anim": {
            "enable": false,
            "speed": 1,
            "opacity_min": 0.1,
            "sync": false
          }
        },
        "size": {
          "value": 80,
          "random": false,
          "anim": {
            "enable": true,
            "speed": 0.5,
            "size_min": 80,
            "sync": true
          }
        },
        "line_linked": {
          "enable": true,
          "distance": 600,
          "color": "#8917c8",
          "opacity": 1,
          "width": 20
        },
        "move": {
          "enable": true,
          "speed": 2,
          "direction": "none",
          "random": true,
          "straight": false,
          "out_mode": "bounce",
          "bounce": false,
          "attract": {
            "enable": true,
            "rotateX": 600,
            "rotateY": 1200
          }
        }
      },
      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": false,
            "mode": "repulse"
          },
          "onclick": {
            "enable": true,
            "mode": "push"
          },
          "resize": true
        },
        "modes": {
          "grab": {
            "distance": 400,
            "line_linked": {
              "opacity": 1
            }
          },
          "bubble": {
            "distance": 400,
            "size": 40,
            "duration": 2,
            "opacity": 8,
            "speed": 3
          },
          "repulse": {
            "distance": 400,
            "duration": 0.4
          },
          "push": {
            "particles_nb": 4
          },
          "remove": {
            "particles_nb": 2
          }
        }
      },
      "retina_detect": true
    })
  }
});
